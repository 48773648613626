export const Features = () => {
  const data = [
    {
      icon: "fa fa-file",
      title: "文档管理",
      text: "优建云能够管理海量的非结构化文档，并支持在线协作和查询",
    },
    {
      icon: "fa fa-table",
      title: "表单管理",
      text: "优建云支持自定以任何格式的办事表单，以无缝接入并支持现有的纸制办事流程",
    },
    {
      icon: "fa fa-tasks",
      title: "流程管理",
      text: "参与并追踪海量项目流程",
    },
    {
      icon: "fa fa-database",
      title: "数据仓库",
      text: "对所有日常项目数据进行集中的存档、查询和统计分析",
    },
  ];

  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>特色功能</h2>
        </div>
        <div className="row">
          {data.map((d, i) => (
            <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
              {" "}
              <i className={d.icon}></i>
              <h3>{d.title}</h3>
              <p>{d.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
