export const Testimonials = () => {
  const data = [
    {
      text: "“优建云是一个辅助政府部门管理各类政府工程的项目在线协同管理解决方案，其主要目的在于协助政府部门运用信息化在线协同的方式更有效的管理工程的全生命周期，帮助政府更容易、更全面的获取项目过程信息，实现低成本、高效率、低风险的工程项目运营，有效保障政府工程的安全成本和质量。同时运用图文并茂的电子项目信息的存档构建一个政府工程资料库，为以后的市政维护、隐患检查等工作提供便捷易查的详实信息，便于长期开展区域内的政府工程的管理工作，降低审计风险。”",
      name: "大良城建局",
    },
    {
      text: "“为更好的实现拆迁工作的规范化、有序化运行；提升拆迁管理工作的及时有效性；彻底改变多点作业，难以集中的管理现实，提高拆迁工作的效率和精确度，大良拆迁办响应建设部加快建设事业信息化的号召，提出了运用信息化手段辅助拆迁工作，并和优建云合作开发了具有独立知识产权的大良城市拆迁管理平台，拓展了拆迁工作的思路，创新了一个新的工作方式。”",
      name: "大良拆迁办",
    },
  ];
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>客户案例</h2>
        </div>
        <div className="row">
          {data.map((d, i) => (
            <div key={`${d.name}-${i}`} className="col-md-4">
              <div className="testimonial">
                {d.img && (
                  <div className="testimonial-image">
                    {" "}
                    <img src={d.img} alt="" />{" "}
                  </div>
                )}
                <div className="testimonial-content">
                  <p>{d.text}</p>
                  <div className="testimonial-meta"> - {d.name} </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
