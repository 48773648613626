import bannerLowerUrl from "../images/banner_lower.jpg";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div
          style={{
            backgroundImage: `url(${bannerLowerUrl})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            position: "absolute",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            opacity: 0.4,
          }}
        />
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="https://console.ublib.com/"
                  className="btn btn-custom btn-lg"
                >
                  进入优建云
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
