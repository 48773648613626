import logo from "../images/logo_text_black.png";

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            <img
              style={{ height: "2em", margin: "-0.5em 0" }}
              src={logo}
              alt="优建云"
            />
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <a
            className="btn btn-custom"
            style={{
              float: "right",
              margin: "4px 0 0 20px",
            }}
            href="https://console.ublib.com/"
          >
            登录
          </a>
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#features" className="page-scroll">
                特色功能
              </a>
            </li>
            <li>
              <a href="#testimonials" className="page-scroll">
                客户案例
              </a>
            </li>
            <li>
              <a href="https://changelog.ublib.com/">更新日志</a>
            </li>
            <li>
              <a href="https://support.ublib.com/">帮助支持</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
